import * as React from 'react';
import { Link, HeadFC } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
//Styles
import '../styles/404.css';

const NotFoundPage = () => {
	return (
		<div className='not_found'>
			<StaticImage
				placeholder='none'
				src='../images/404/orbitWeb_AstroFlag.png'
				className='not_found-astronaut'
				alt='astronaut-flag-img'
			/>
			<h1>404 NOT FOUND!</h1>
			<p>Sorry, The Content You're Looking For Could Not Be Found</p>
			<div className='not_found__buttons'>
				<Link className='not_found__buttons-button' to='/'>
					Home
				</Link>
				<Link className='not_found__buttons-button' to='/'>
					About us
				</Link>
			</div>
		</div>
	);
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not Found | 404</title>;
